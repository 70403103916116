<div class="container-fluid px-0">
    <div class="row mt-4" style="position: absolute; width: 100%">
      <div class="col-6 col-md-4 col-lg-6">
        <a href="/">
          <img
            width="1"
            height="1"
            src="/assets/images/vtc.png"
            alt="logo-prinicpal"
            class="logo-principal"
          />
        </a>
        <h1 style="position: absolute; opacity: 0">Sobre Nosotros</h1>
      </div>
      <div class="d-none d-md-block col-md-8 col-lg-6 text-white text-right blur-background" style="text-align: right; padding-right: 20px; ">
        <span style="text-align: right; color: white; text-decoration: none;">
        <a href="/home" style="color: white; text-decoration: none;">
          Inicio
          <span style="margin-left: 15px;">|</span>
        </a>
        @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('//visatravelcenter.com')){
          <a href="https://ec.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none;  margin-left: 15px">
            Asesoría Según tu Destino
            <span style="margin-left: 15px;">|</span>
          </a>
          <a href="https://ec.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
            Asesoría Para Estudios
            <span style="margin-left: 15px;">|</span>
          </a>
        }
        @if( baseUrl.includes('co.visatravelcenter') ){
          <a href="https://co.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none; margin-left: 15px">
            Asesoría Según tu Destino
            <span style="margin-left: 15px;">|</span>
          </a>
          <a href="https://co.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
            Asesoría Para Estudios
            <span style="margin-left: 15px;">|</span>
          </a>
        }
  
        @if( baseUrl.includes('go.visatravelcenter') || baseUrl.includes('localhost')){
          <a href="https://go.visatravelcenter.com/asesoria-migratoria" style="color: white; text-decoration: none; margin-left: 15px">
            Asesoría Según tu Destino
            <span style="margin-left: 15px;">|</span>
          </a>
          <a href="https://go.visatravelcenter.com/asesoria-migratoria-estudios-exterior" style="color: white; text-decoration: none; margin-left: 15px">
            Asesoría Para Estudios
            <span style="margin-left: 15px;">|</span>
          </a>
        }
        <a href="/blog" style="color: white; text-decoration: none; margin-left: 15px">
          Blog
          <span style="margin-left: 15px;">|</span>
        </a>
  
        @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('go.visatravelcenter') || baseUrl.includes('//visatravelcenter.com') || baseUrl.includes('localhost')){
          <a href="https://api.whatsapp.com/send?phone=5930990414263&text=Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20asesorias%20migratorias!" target="_blank" class="footer-link" style="color: white; text-decoration: none;">
            <img
            width="1"
            height="1"
            src="/assets/images/whatsapp.png"
            alt="whatsappnicpal"
            style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
          />
            +5930990414263
            <img
              width="1"
              height="1"
              src="/assets/images/ecuador.png"
              alt="banner-prinicpal"
              style="width:16px; height: auto; margin-top: -5px; margin-right: 10px"
            />
          </a>
        }
        @if( baseUrl.includes('co.visatravelcenter')){
          <a href="https://api.whatsapp.com/send?phone=573157188982&text=Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20asesorias%20migratorias!" target="_blank" class="footer-link">
            <img
              width="1"
              height="1"
              src="/assets/images/whatsapp.png"
              alt="whatsappnicpal"
              style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
            />
            +57 315 7188982
            <img
            width="1"
            height="1"
            src="/assets/images/colombia.png"
            alt="whatsappnicpal"
            style="width:16px; height: auto; margin-top: -5px"
          /></a>
        }</span>
      </div>
      @if( baseUrl.includes('ec.visatravelcenter') || baseUrl.includes('go.visatravelcenter') || baseUrl.includes('//visatravelcenter.com') || baseUrl.includes('localhost')){
        <div class="col-6 d-block d-md-none text-right">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=5930990414263&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
            <img
              width="1"
              height="1"
              src="/assets/images/ws-mobile.png"
              alt="contacto whatsapp"
              style="height: 30px; width: auto; margin-top: 5px;"
              class=""
            />
          </a>
        </div>
      }
      @if( baseUrl.includes('co.visatravelcenter')){
        <div class="col-6 d-block d-md-none text-right">
  
          <a target="_blank" href="https://api.whatsapp.com/send?phone=573157188982&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
            <img
              width="1"
              height="1"
              src="/assets/images/ws-mobile.png"
              alt="contacto whatsapp"
              style="height: 30px; width: auto; margin-top: 5px;"
              class=""
            />
          </a>
        </div>
      }
    </div>
    <img
      width="1"
      height="1"
      src="/assets/images/cookies-main.png"
      alt="banner-prinicpal"
      style="width: 100%; height: auto;"
      class="d-none d-md-block"
    />
    <img
      width="1"
      height="1"
      src="/assets/images/cookies-mobile.png"
      alt="banner-prinicpal"
      style="width: 100%; height: auto;"
      class="d-block d-md-none"
    />
  </div>
  
  <div class="container">
    <div class="row px-3 px-md-5">
      <div class="about-us-box mt-5 mb-5 px-md-5">
        <p class="about-us">
          <span style="font-weight: 600; font-size: 30px">COOKIES</span> <br><br>
          <span style="font-weight: 600;  font-size: 22px">¿Qué son las Cookies?</span><br><br>
          Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando
            interactúas con nuestro sitio web. Estas cookies están diseñadas para almacenar información que
            facilita tu experiencia de navegación y permite que el sitio web funcione de manera más eficiente.
            Por ejemplo, las cookies pueden recordar tus preferencias de idioma, ajustes de visualización y
            detalles de inicio de sesión para proporcionarte una experiencia más personalizada en futuras
            visitas al sitio. Además, las cookies pueden ser utilizadas por terceros para recopilar datos sobre
            tus hábitos de navegación con el fin de ofrecerte publicidad relevante y mejorar la funcionalidad
            del sitio.<br><br>
        
          <span style="font-weight: 600;  font-size: 22px">¿Cómo se utilizan en el Sitio Web?</span><br><br>
          En nuestro sitio web, utilizamos cookies para diversos propósitos:<br><br>
          1. Funcionalidad del Sitio: Utilizamos cookies para recordar tus preferencias de idioma y
          configuración, lo que permite una experiencia de usuario más personalizada y eficiente.
          Por ejemplo, estas cookies pueden recordar tu idioma preferido para mostrar el contenido
          del sitio en el idioma que elijas y recordar tus ajustes de visualización para adaptar el
          diseño del sitio a tus preferencias.<br><br>
          2. Análisis del Sitio: Empleamos cookies de análisis para recopilar información sobre cómo
          interactúas con nuestro sitio web. Estas cookies registran datos como las páginas que
          visitas, el tiempo que pasas en cada página, los enlaces en los que haces clic y otras
          acciones que realizas durante tu visita. Esta información nos ayuda a comprender mejor
          cómo se utiliza el sitio, qué áreas pueden necesitar mejoras y cómo podemos optimizar la
          experiencia del usuario.<br><br>
          3. Publicidad Personalizada: Algunas cookies se utilizan para mostrar anuncios adaptados a
          tus intereses y comportamientos de navegación. Estas cookies recopilan información
          sobre tus hábitos de navegación en nuestro sitio y en otros sitios web para personalizar la
          publicidad que ves. Por ejemplo, si has mostrado interés en un producto o servicio
          específico, es posible que veas anuncios relacionados cuando navegues por nuestro sitio
          web. Esto nos permite ofrecerte contenido publicitario relevante y mejorar la efectividad
          de nuestras campañas de marketing.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Tipo de Información que se Recopila:</span><br><br>
          Las cookies pueden recopilar una variedad de datos mientras navegas por nuestro sitio web:<br><br>
          1. Información de navegación: Esto incluye las páginas que visitas, los enlaces en los que
          haces clic, el tiempo que pasas en cada página y la frecuencia con la que vuelves al sitio.
          Estos datos nos ayudan a comprender cómo los usuarios interactúan con nuestro sitio y a
          mejorar su diseño y funcionalidad.<br><br>
          2. Preferencias del usuario: Las cookies pueden almacenar tus preferencias de usuario,
          como el idioma preferido, las configuraciones de visualización y cualquier otra preferencia
          personalizada que hayas seleccionado. Esto nos permite ofrecerte una experiencia de
          navegación más personalizada y adaptada a tus necesidades individuales.<br><br>
          3. Datos de inicio de sesión: Si optas por guardar tu información de inicio de sesión, como
          nombres de usuario y contraseñas, las cookies pueden recordar esta información para que
          no tengas que volver a ingresarla cada vez que visites el sitio. Sin embargo, es importante
          destacar que esta información se almacena de forma segura y solo se utiliza con tu
          consentimiento explícito.<br><br>

          Es fundamental destacar que la información recopilada por las cookies es anónima y se utiliza de
            forma agregada para fines estadísticos y de análisis. No recopilamos ni almacenamos información
            personal identificable sin tu consentimiento explícito. Tu privacidad y seguridad son nuestras
            principales prioridades, y nos comprometemos a proteger tus datos de acuerdo con las leyes y
            regulaciones aplicables.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Tu Consentimiento:</span><br><br>
          Al utilizar nuestro sitio web, aceptas el uso de cookies de acuerdo con esta política. Esto significa
          que estás de acuerdo con que almacenemos y accedamos a las cookies en tu dispositivo para los
          fines descritos anteriormente.<br><br>
          Es importante tener en cuenta que puedes gestionar las cookies a través de la configuración de tu
          navegador. En la configuración de tu navegador, generalmente puedes encontrar opciones para
          aceptar, rechazar o eliminar cookies. Sin embargo, desactivar ciertas cookies puede afectar la
          funcionalidad del sitio y limitar tu experiencia de navegación.<br><br>
          Si deseas obtener más detalles sobre cómo utilizamos las cookies y cómo protegemos tu
          privacidad, te recomendamos que consultes nuestra Política de Privacidad. Esta política describe
          en detalle qué información recopilamos, cómo la utilizamos y cómo la protegemos. Tu privacidad
          es importante para nosotros, y nos comprometemos a proteger tus datos de acuerdo con las leyes
          y regulaciones aplicables.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Conclusiones:</span><br><br>
          En conclusión, las cookies desempeñan un papel fundamental en la mejora de tu experiencia en
          nuestro sitio web. Almacenando información sobre tus preferencias y comportamiento de
          navegación, las cookies nos permiten ofrecerte un contenido más relevante y personalizado.
          Además, nos brindan datos valiosos sobre cómo interactúas con nuestro sitio, lo que nos ayuda a
          mejorar su diseño y funcionalidad.
          Al aceptar el uso de cookies, estás contribuyendo a que podamos proporcionarte un servicio más
          adaptado a tus necesidades y preferencias. Sin embargo, entendemos que la privacidad es
          importante para ti, por lo que te ofrecemos la opción de gestionar tus preferencias de cookies a
          través de la configuración de tu navegador.
          En última instancia, nuestro objetivo es proporcionarte una experiencia de usuario satisfactoria y
          segura en nuestro sitio web. Apreciamos tu confianza y apoyo al aceptar el uso de cookies, y nos
          comprometemos a seguir mejorando nuestros servicios para brindarte la mejor experiencia
          posible.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Uso de Cookies y Consentimiento del Usuario</span><br><br>
          <span style="font-weight: 600;  font-size: 22px">¿Qué son las Cookies?</span><br><br>
          Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando
          interactúas con nuestro sitio web. Estas cookies contienen información que nos permite recordar
          tus acciones y preferencias, como el idioma seleccionado, la configuración de visualización y las
          interacciones pasadas con el sitio. Gracias a estas cookies, podemos ofrecerte una experiencia de
          navegación más eficiente y personalizada, adaptada a tus necesidades y preferencias individuales.<br><br>
          Uso de Cookies en nuestro Sitio Web:<br><br>
          En nuestro sitio web, utilizamos cookies con los siguientes propósitos:<br><br>
          ● Funcionalidad del sitio: Las cookies nos permiten recordar tus preferencias de idioma y
            configuración, lo que garantiza una experiencia de usuario más eficiente y personalizada.
            Por ejemplo, estas cookies pueden recordar tu idioma preferido para mostrar el contenido
            en el idioma adecuado.<br><br>
            ● Análisis del sitio: Utilizamos cookies de análisis para recopilar información sobre cómo
            interactúas con nuestro sitio web. Esto incluye datos como las páginas que visitas, el
            tiempo que pasas en cada página y las acciones que realizas durante tu visita. Esta
            información nos proporciona una visión detallada del rendimiento del sitio y nos ayuda a
            identificar áreas de mejora en términos de diseño, navegación y contenido.<br><br>
            ● Publicidad personalizada: Empleamos cookies para ofrecerte anuncios adaptados a tus
            intereses y comportamientos de navegación. Estas cookies recopilan información sobre
            tus visitas al sitio web y pueden utilizarse para mostrar anuncios relevantes cuando
            navegas por otros sitios web. El objetivo es proporcionarte contenido publicitario que sea
            más relevante y atractivo para ti, así como mejorar la efectividad de nuestras campañas
            publicitarias.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Necesidad de Consentimiento:</span><br><br>
          Es esencial que comprendas que tu consentimiento es fundamental para el uso de cookies en
        nuestro sitio web. Antes de utilizar cookies que no sean esenciales para el funcionamiento del
        sitio, te solicitaremos tu aprobación explícita a través de un mensaje de consentimiento o una
        ventana emergente en el sitio web. Esto significa que tendrás la oportunidad de decidir si aceptas
        o rechazas el uso de cookies que no sean estrictamente necesarias para el funcionamiento básico
        del sitio. Tu consentimiento nos permite garantizar que estás informado sobre el uso de cookies y
        que tienes el control sobre tu experiencia de navegación en nuestro sitio web.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Cómo Dar tu Consentimiento:</span><br><br>
          Cuando ingreses a nuestro sitio web por primera vez, te presentaremos un mensaje de
          consentimiento que te solicitará que aceptes el uso de cookies. En este mensaje, tendrás la opción
          de aceptar todas las cookies o de configurar tus preferencias de cookies de acuerdo con tus
          necesidades específicas. Si optas por configurar tus preferencias, podrás seleccionar qué tipos de
          cookies deseas aceptar y cuáles prefieres rechazar. Además, siempre tendrás la libertad de
          cambiar tus preferencias de cookies en cualquier momento a través de la configuración de tu
          navegador. Esto te brinda un control total sobre el uso de cookies en nuestro sitio web y te
          permite ajustar tus preferencias según evolucionen tus necesidades o preferencias de navegación.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Tu Control sobre las Cookies:</span><br><br>
          Entendemos la importancia de que tengas el control total sobre el uso de cookies en nuestro sitio
          web. Por ello, te ofrecemos la posibilidad de gestionar y controlar tus preferencias de cookies en
          cualquier momento a través de la configuración de tu navegador.<br><br>
          Mediante la configuración de tu navegador, podrás habilitar o deshabilitar diferentes tipos de
          cookies según tus preferencias personales. Sin embargo, es importante tener en cuenta que
          desactivar ciertas cookies puede afectar la funcionalidad del sitio y limitar tu experiencia de
          navegación. Por ejemplo, algunas cookies son esenciales para que el sitio funcione correctamente
          y te permitan acceder a determinadas funciones. Si desactivas estas cookies, es posible que
          algunas partes del sitio no estén disponibles para ti o que no funcionen correctamente.<br><br>
          Por lo tanto, te recomendamos que revises cuidadosamente tus preferencias de cookies y
          consideres las implicaciones antes de realizar cambios en la configuración de tu navegador. Si
          necesitas más información sobre cómo gestionar tus cookies o sobre el impacto que pueden tener
          en tu experiencia de navegación, no dudes en ponerte en contacto con nosotros. Estamos aquí
          para ayudarte a tomar decisiones informadas y garantizar que tengas la mejor experiencia posible en nuestro sitio web.<br><br>
  
          <span style="font-weight: 600;  font-size: 22px">Conclusiones:</span><br><br>
  
          En conclusión, en Visa Travel Center, valoramos tu privacidad y la seguridad de tus datos como
          nuestra máxima prioridad. Te pedimos que revises detenidamente esta política de cookies para
          comprender cómo se utilizan y cómo afectan tu experiencia en nuestro sitio web. Tu
          consentimiento para el uso de cookies es fundamental para garantizar una navegación segura y
          personalizada.<br><br>
          Si tienes alguna pregunta o inquietud sobre el uso de cookies o cualquier otro aspecto relacionado
          con la privacidad de tus datos, no dudes en ponerte en contacto con nosotros. Estamos aquí para
          brindarte la asistencia necesaria y garantizar que tu experiencia en nuestro sitio web sea óptima y
          satisfactoria. Tu confianza es importante para nosotros y trabajamos continuamente para proteger
          tu privacidad y mantener altos estándares de seguridad en nuestro sitio web.<br><br>
        </p>
      </div>
    </div>
  </div>
  
  <footer class="container-fluid footer-bg footer pb-4">
    <div class="container d-none d-lg-block">
      <div class="row pt-5">
        <div class="col">
          <p class="text-white footer-title">Nuestros locales</p>
          <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
          <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
          <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
          <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>
  
          <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
          <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
        </div>
        <div class="col">
          <p class="footer-title">Ventas</p>
          <p style="margin-bottom: 0px;">
            <img
              width="18"
              height="20"
              class="ws-footer"
              src="/assets/images/whatsapp.png"
              alt="visatravelcenter whatsapp"
              style="margin-right: 2px; margin-bottom: 2px"
            />
            <span class="text-white" style="font-weight: 600; font-size: 18px">+5930990414263</span>
            <img
            width="15"
            height="15"
            class="ws-footer"
            src="/assets/images/ecuador.png"
            alt="visatravelcenter whatsapp colombia"
            style="margin-left: 5px; margin-bottom: 5px"
          />
          </p>
          <p style="margin-top: 5px;">
            <img
              width="18"
              height="20"
              class="ws-footer"
              src="/assets/images/whatsapp.png"
              alt="visatravelcenter whatsapp"
              style="margin-right: 2px; margin-bottom: 2px"
            />
            <span class="text-white" style="font-weight: 600; font-size: 18px">+57 315 7188982</span>
            <img
            width="15"
            height="15"
            class="ws-footer"
            src="/assets/images/colombia.png"
            alt="visatravelcenter whatsapp colombia"
            style="margin-left: 5px; margin-bottom: 5px"
          />
          </p>
          <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
            Sábados de 9 a 13 hs</p>
        </div>
        <div class="col">
          <p class="footer-title">Productos</p>
          <p class="products-list">
            <a href="/home" style="color: white; text-decoration: none">Visas</a>
          </p>
          <p class="products-list">
            <a href="/home" style="color: white; text-decoration: none">Seguros de viajes</a>
            <span></span>
          </p>
          <p class="products-list">
            <a href="/home"style="color: white; text-decoration: none">Paquetes</a>
          </p>
          <a href="/blog" style="text-decoration: none;">
            <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Blog</span><br /></p>
          </a>
          <a href="/home" style="text-decoration: none;">
            <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Inicio</span><br /></p>
          </a>
        </div>
        <div class="col">
          <p class="footer-title">Legales</p>
          <p class="text-white" style="font-weight: 400; font-size: 14px">
            Cumplimos con los permisos exigidos. ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP- SEVIFRAL S.A.
          </p>
          <a href="/politicas-privacidad" style="text-decoration: none;">
            <p class="text-white"><span class="font-size-15 bolder-d gris-claro">Políticas de privacidad</span><br /></p>
          </a>
        </div>
        <div class="col">
          <div>
            <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
              <div class="mt-3">
                <span class="mt-4">
                  <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                    <img
                      width="30"
                      height="30"
                      class="red-social"
                      src="/assets/images/social-fb.png"
                      alt="visatravelcenter facebook"
                      style="margin-right: 10px;"
                    />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                    <img
                      width="30"
                      height="30"
                      class="red-social"
                      src="/assets/images/social-ig.png"
                      alt="visatravelcenter Instagram"
                      style="margin-right: 10px;"
                    />
                  </a>
                  <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                    <img
                      width="30"
                      height="30"
                      class="red-social"
                      src="/assets/images/social-tk.png"
                      alt="visatravelcenter tiktok"
                      style="margin-right: 10px;"
                    />
                  </a> </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-lg-none pb-5">
      <div class="col-12 mt-3 text-center">
        <p class="text-white footer-title">Nuestros locales</p>
        <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
        <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
        <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
        <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>
  
        <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
        <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
      </div>
      <div class="col-12 text-center mt-4">
        <p class="footer-title">Ventas</p>
      <p style="margin-bottom: 0px;">
        <img
          width="18"
          height="20"
          class="ws-footer"
          src="/assets/images/whatsapp.png"
          alt="visatravelcenter whatsapp"
          style="margin-right: 2px; margin-bottom: 2px"
        />
        <span class="text-white" style="font-weight: 600; font-size: 18px">+5930990414263</span>
        <img
        width="15"
        height="15"
        class="ws-footer"
        src="/assets/images/ecuador.png"
        alt="visatravelcenter whatsapp colombia"
        style="margin-left: 5px; margin-bottom: 5px"
      />
      </p>
      <p style="margin-top: 5px;">
        <img
          width="18"
          height="20"
          class="ws-footer"
          src="/assets/images/whatsapp.png"
          alt="visatravelcenter whatsapp"
          style="margin-right: 2px; margin-bottom: 2px"
        />
        <span class="text-white" style="font-weight: 600; font-size: 18px">+57 315 7188982</span>
        <img
        width="15"
        height="15"
        class="ws-footer"
        src="/assets/images/colombia.png"
        alt="visatravelcenter whatsapp colombia"
        style="margin-left: 5px; margin-bottom: 5px"
      />
      </p>
      <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
        Sábados de 9 a 13 hs</p>
    </div>
    <div class="col-12 text-center">
      <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
        <span class="mt-4">
          <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
            <img
              width="30"
              height="30"
              class="red-social"
              src="/assets/images/social-fb.png"
              alt="visatravelcenter facebook"
              style="margin-right: 10px;"
            />
          </a>
          <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
            <img
              width="30"
              height="30"
              class="red-social"
              src="/assets/images/social-ig.png"
              alt="visatravelcenter Instagram"
              style="margin-right: 10px;"
            />
          </a>
          <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
            <img
              width="30"
              height="30"
              class="red-social"
              src="/assets/images/social-tk.png"
              alt="visatravelcenter tiktok"
              style="margin-right: 10px;"
            />
          </a> </span>
    </div>
    </div>
  </footer>
  
  <div class="container-fluid d-none d-lg-block" style="background-color: #183755">
    <div class="row">
      <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px; font-weight: 300">
          <img
            width="180"
            height="40"
            class="red-social"
            src="assets/images/vtc.png"
            alt="logo vtc"
            style="margin-right: 20px; width: 180px !important; height: auto"
        />
        2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.
      </div>
    </div>
  </div>
  
  <div class="container-fluid d-lg-none" style="background-color: #183755">
    <div class="row">
      <div class="col-12 text-white text-center d-none d-lg-block" style="padding-top: 20px; padding-bottom: 20px">
          <img
            width="180"
            height="40"
            class="red-social"
            src="assets/images/vtc.png"
            alt="logo vtc"
            style="margin-right: 20px;"
        />
      </div>
      <div class="col-12 text-white text-center d-block d-lg-none" style="padding-top: 20px; padding-bottom: 20px">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px; height: auto; width: 200px !important"
      />
    </div>
    </div>
    <div class="row">
      <div class="col-12 text-white text-center" style="padding-bottom: 20px; font-weight: 400">
        <span style="font-weight: 300">2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.</span>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" >
      <div class="modal-content" style="border-radius: 24px; padding: 5px 16px">
        <div class="modal-header" style="border-bottom: none;">
          <h3 class="modal-title" id="staticBackdropLabel" style="font-size: 30px; font-family: 'Yeseva One', serif; margin-top: 7px">{{ selectedVisa.name }}</h3>
          <a data-bs-dismiss="modal" aria-label="Close" style="background-color: none; text-decoration: none; cursor: pointer;">x Cerrar</a>
        </div>
        <div class="modal-body">
          {{ selectedVisa.description }}
          @if(currentTab == "visa"){
            <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y recibe tu asesoría</p>
          }
          @if(currentTab == "paquetes"){
            <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y reserva tu viaje. Este incluye:</p>
            <div>
              <ul>
                @for (benefit of selectedVisa.benefits; track benefit) {
                  <li>{{benefit}}</li>
                }
              </ul>
            </div>
          }
          @if(currentTab == "seguros"){
            <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Viaja protegido. Completa tus datos ahora y cotiza tu plan. Este incluye:</p>
            <div>
              <ul>
                @for (benefit of selectedVisa.benefits; track benefit) {
                  <li>{{benefit}}</li>
                }
              </ul>
            </div>
          }
          <div class="row">
            <div class="col-6">
              <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
              <input type="text" class="form-control" placeholder="Nombre Completo" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
            </div>
            <div class="col-6">
              <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
              <input type="text" class="form-control" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
            </div>
            <div class="col-6">
              <img src="/assets/images/form-people.png" alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
              <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
                <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
                @for (passenger of passengers; track passenger) {
                  <option [value]="passenger">Pasajeros: {{ passenger }}</option>
                }
  
              </select>
            </div>
            <div class="col-6">
              <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
              <input type="text" class="form-control" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              @if(currentTab == "visa"){
                <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Asesoría</button>
              }
              @if(currentTab == "paquetes"){
                <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Paquete</button>
              }
              @if(currentTab == "seguros"){
                <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Seguro de Viaje</button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  