import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { CookiesComponent } from './pages/cookies/cookies.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'nosotros',
    component: AboutComponent
  },
  {
    path: 'politicas-privacidad',
    component: PoliciesComponent
  },
  {
    path: 'politicas-cookies',
    component: CookiesComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];
