import { Component, signal, OnInit } from '@angular/core';
import { Destinations } from '../../resources/destinations';
import { DatePipe } from '@angular/common';
import { Packages } from '../../resources/packages';
import { Plans } from '../../resources/plans';
import { PlanByCountry } from '../../resources/planByCountry';
import { Visas } from '../../resources/visas';
import { CustomerService } from '../../services/customer.service';
import { environment } from '../../../enviroments/environment';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cookies',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [CustomerService],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss'
})
export class CookiesComponent {
    public visas = Destinations.global.visas;
    public paquetes = Packages.global.paquetes;
    public planes = Plans.global.planes;
    public visados = Visas.visados;
    public planesPais = PlanByCountry.planes;
    public selectedVisa = this.visas[0];
    public passengers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    public showModal = "";
    public currentTab = 'visa';
    public visasSearch = false;
    public visaSent = false;
    public loadingMain = false;
    public countries = [];
    public visasChunks: any[] = [];
    public paquetesChunks: any[] = [];
    public planesChunks: any[] = [];
    public matchingVisado: any = null;
    public matchingPlan: any = null;
    public allSelect = false;
    public visaError = false;
    public planError = false;
    public paqueteError = false;
    public selectedPassport: string = '';
    public selectedOrigin: string = '';
    public selectedDestiny: string = '';
    public selectedType: string = '';
    public originCountry = null;
    public currentAge = null;
    public initialDate = null;
    public finalDate = null;
    public baseUrl = window.location.href;
  
    fullName: string = '';
    phone: string = '';
    email: string = '';
    fullNameError: string = '';
    phoneError: string = '';
    emailError: string = '';
    destiny_id: string = '';
    origin_id: string = '';
    type_visa: string = '';
    visas_destiny: string = '0';
    visas_origin: string = '0';
  
    name = signal('Angular');
  
    constructor(private customerService: CustomerService) {
  
    }
  
    ngOnInit() {
      this.getCountries();
      this.splitVisasIntoChunks();
    }
  
    getCountries() {
      this.customerService.getCountries().subscribe(
        (resp: any) => {
          console.log('Countries:', resp);
          this.countries = resp['data'];
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    }
  
  
    splitVisasIntoChunks() {
      for (let i = 0; i < this.visas.length; i += 4) {
        this.visasChunks.push(this.visas.slice(i, i + 4));
      }
      for (let i = 0; i < this.paquetes.length; i += 4) {
        this.paquetesChunks.push(this.paquetes.slice(i, i + 4));
      }
      for (let i = 0; i < this.planes.length; i += 4) {
        this.planesChunks.push(this.planes.slice(i, i + 4));
      }
    }
  
    selectVisa(id: number, show: string) {
      if(show === 'visa') {
        this.showModal = 'visa';
        let visa = this.visas.find(visa => visa.id === id);
        if (visa) {
          this.selectedVisa = visa;
        } else {
          console.error(`Visa with id ${id} not found`);
        }
      }
      if(show === 'paquetes') {
        this.showModal = 'paquetes';
        let paquete = this.paquetes.find(paquete => paquete.id === id);
        if (paquete) {
          this.selectedVisa = paquete;
        } else {
          console.error(`Visa with id ${id} not found`);
        }
      }
      if(show === 'seguros') {
        this.showModal = 'seguros';
        let plan = this.planes.find(plan => plan.id === id);
        if (plan) {
          this.selectedVisa = plan;
        } else {
          console.error(`Plan with id ${id} not found`);
        }
      }
    }
  
    searchVisa(type: string) {
      console.log('Search visa');
      console.log(this.selectedPassport)
      console.log(this.selectedOrigin)
      console.log(this.selectedDestiny)
      console.log(this.selectedType)
      this.visaError = false;
      this.planError = false;
      this.paqueteError = false;
      if(type === 'visa') {
        if(this.selectedPassport === '' || this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.selectedType === '') {
          this.visaError = true;
        }else {
          if(this.selectedOrigin == this.selectedDestiny) {
            this.visaError = true;
          }else {
            this.originCountry = null;
            let destinyCountry = null;
  
            for (let country of this.countries) {
              console.log('Country:', country["id"]);
              if (country["id"] == this.selectedOrigin) {
                this.originCountry = country["name"];
              }
              if (country["id"] == this.selectedDestiny) {
                destinyCountry = country;
              }
              if (this.originCountry && destinyCountry) {
                break;
              }
            }
  
            console.log('Origin country:', this.originCountry);
            console.log('Destiny country:', destinyCountry);
            if (this.originCountry && destinyCountry) {
              this.matchingVisado = this.visados.visas.find(visado =>
                visado.pasaporte.localeCompare(this.selectedPassport, undefined, { sensitivity: 'base' }) === 0 &&
                visado.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0 &&
                visado.tipo.localeCompare(this.selectedType, undefined, { sensitivity: 'base' }) === 0
              );
  
              this.matchingVisado.requisitosArray = [];
  
              if (this.matchingVisado) {
                console.log('Matching first:', this.matchingVisado);
                if(this.matchingVisado.requisitos) {
                    const requisitosArray = this.matchingVisado.requisitos.split('\n');
                    this.matchingVisado.requisitosArray = requisitosArray;;
                } else {
                  this.matchingVisado.requisitos = [];
                }
                console.log('Matching visado found:', this.matchingVisado);
                this.visaSent = false;
              } else {
                console.log('No matching visado found');
                this.visaSent = false;
              }
            } else {
              console.log('Origin or destiny country not found');
              this.visaSent = false;
            }
  
            if(this.visasSearch) {
              this.visaSent = false;
            }
            this.visasSearch = !this.visasSearch ;
          }
        }
      }
      if(type === 'plan') {
        this.visasSearch = false;
        console.log('Search visa');
        console.log(this.selectedOrigin)
        console.log(this.selectedDestiny)
        console.log(this.currentAge)
        console.log(this.initialDate)
        console.log(this.finalDate)
        this.visaError = false;
          if(this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.currentAge === null || this.initialDate === null || this.finalDate === null) {
            this.planError = true;
          }else {
            if(this.selectedOrigin == this.selectedDestiny) {
              this.planError = true;
            }else {
            this.originCountry = null;
            let destinyCountry = null;
  
            for (let country of this.countries) {
              console.log('Country:', country["id"]);
              if (country["id"] == this.selectedOrigin) {
                this.originCountry = country["name"];
              }
              if (country["id"] == this.selectedDestiny) {
                destinyCountry = country;
              }
              if (this.originCountry && destinyCountry) {
                break;
              }
            }
  
            console.log('Origin country:', this.originCountry);
            console.log('Destiny country:', destinyCountry);
            if (this.originCountry && destinyCountry) {
              this.matchingPlan = this.planesPais.find((plan: any) =>
                plan.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0
              );
  
              if (this.matchingPlan) {
                console.log('Matching first:', this.matchingPlan);
                if(this.currentAge > this.matchingPlan.maximo) {
                  if(this.matchingPlan.destino === 'ESTADOS UNIDOS DE AMERICA') {
                    this.matchingPlan["plan"] = "Plan estandar",
                    this.visaSent = false;
                  }else {
                    this.matchingPlan["plan"] = "Plan ideal",
                    this.visaSent = false;
                  }
  
                }
              } else {
                console.log('No matching plan found');
                this.matchingPlan = {
                  plan: "Plan ideal",
                  destino: "No se encontraron planes para el destino seleccionado",
                  maximo: 0
                }
                this.visaSent = false;
              }
            } else {
              console.log('Origin or destiny country not found');
              this.visaSent = false;
            }
  
            if(this.visasSearch) {
              this.visaSent = false;
            }
            this.visasSearch = !this.visasSearch ;
          }
        }
      }
      if(type === 'paquete') {
        this.visasSearch = false;
        console.log('Search visa paquete');
        console.log(this.selectedOrigin)
        console.log(this.selectedDestiny)
        console.log(this.initialDate)
        console.log(this.finalDate)
        this.visaError = false;
          if(this.selectedOrigin === '0' || this.selectedDestiny === '0' || this.initialDate === null || this.finalDate === null) {
            this.paqueteError = true;
          }else {
            if(this.selectedOrigin == this.selectedDestiny) {
              this.paqueteError = true;
            }else {
            this.originCountry = null;
            let destinyCountry = null;
  
            for (let country of this.countries) {
              console.log('Country:', country["id"]);
              if (country["id"] == this.selectedOrigin) {
                this.originCountry = country["name"];
              }
              if (country["id"] == this.selectedDestiny) {
                destinyCountry = country;
              }
              if (this.originCountry && destinyCountry) {
                break;
              }
            }
  
            console.log('Origin country:', this.originCountry);
            console.log('Destiny country:', destinyCountry);
            if (this.originCountry && destinyCountry) {
              this.matchingPlan = this.planesPais.find((plan: any) =>
                plan.destino.localeCompare(destinyCountry["name"], undefined, { sensitivity: 'base' }) === 0
              );
  
            this.visaSent = false;
            } else {
              console.log('Origin or destiny country not found');
              this.visaSent = false;
            }
  
            if(this.visasSearch) {
              this.visaSent = false;
            }
            this.visasSearch = !this.visasSearch ;
          }
        }
      }
    }
  
    currentTabSet(tab: string) {
      this.currentTab = tab;
      this.visasSearch = false;
    }
  
    scrollToTop(form: string) {
      //this.currentTab = form;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  
    sendVisa() {
      this.fullNameError = '';
      this.phoneError = '';
      this.emailError = '';
  
      if (this.fullName.trim() === '') {
        this.fullNameError = 'El nombre completo es requerido.';
        console.error('Full name is required.');
        return;
      }
  
      if (this.fullName.trim().length < 4) {
        this.fullNameError = 'El nombre completo debe tener al menos 4 caracteres.';
        console.error('Invalid full name. It must be at least 4 characters long.');
        return;
      }
  
      if (this.fullName.trim().length > 100) {
        this.fullNameError = 'El nombre completo debe tener menos de 100 caracteres.';
        console.error('Invalid full name. It must be no more than 100 characters long.');
        return;
      }
  
      const fullNamePattern = /^[A-Za-z\s]+$/;
      if (!fullNamePattern.test(this.fullName.trim())) {
        this.fullNameError = 'El nombre completo debe contener solo letras.';
        console.error('Invalid full name. It must contain only letters.');
        return;
      }
  
  
      if (this.phone.trim().length < 7) {
        this.phoneError = 'El número de teléfono debe tener al menos 7 dígitos.';
        console.error('Invalid phone number. It must be at least 6 digits long.');
        return;
      }
  
      if (this.phone.trim().length > 14) {
        this.phoneError = 'El número de teléfono debe tener maximo de 14 dígitos.';
        console.error('Invalid phone number. It must be no more than 14 digits long.');
        return;
      }
  
      const phonePattern = /^[0-9]+$/;
      if (!phonePattern.test(this.phone.trim())) {
        this.phoneError = 'El número de teléfono debe contener solo números.';
        console.error('Invalid phone number. It must contain only numbers.');
        return;
      }
  
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email.trim())) {
        this.emailError = 'La dirección de correo electrónico no es válida.';
        console.error('Invalid email address.');
        return;
      }
  
      this.visaSent = true;
      this.loadingMain = true;
      this.customerService
      let finalName = this.fullName.trimStart()
      if(this.currentTab === 'visa') {
        finalName = this.fullName.trimStart() + ' | ' + "Pasaporte: " + this.selectedPassport.trimStart() + ' | ' + this.originCountry
      }else if(this.currentTab === 'paquetes') {
        finalName = this.fullName.trimStart() + '| origen' + this.originCountry + ' - Paquete'
      }else if(this.currentTab === 'seguros') {
        finalName = this.fullName.trimStart() + ' | Recomendado: ' + this.matchingPlan.plan
      }
      this.customerService.postCustomer(environment.serverUrl + '/api/services', {
        destiny_id: this.visas_destiny,
        origin_id: this.visas_origin,
        type: 'assistance',
        data: {
          tld: 'com',
          name: finalName,
          lastname: "",
          email: this.email.trimStart(),
          phone: this.phone.trimStart(),
          source: window.location.href,
        },
      })
      .subscribe(
        (resp: any) => {
          this.loadingMain = false;
          //this.router.navigate(['cotizar', resp.hashcode]);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
}
